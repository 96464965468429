<template>
	<div class="container">
		<div class="top-container">
			<div style="padding: 20px;">
				<img class="back-icon" src="../../public/static/back.png" alt="" @click="back">
			</div>
			<div v-if="album.user_id" class="head-pic bg-img" @click="toMtDetail(album.user_id)" :style="'background-image: url('+album.head_ico+');'"></div>
			<div class="info-con" @click="toMtDetail(album.user_id)" v-if="album.user_id">
				<div class="mt-name">{{album.mt_name}}</div>
				<div class="age-con">
					<div v-if="album.mt_age">{{album.mt_age}}岁·</div>
					<div>{{album.province}}·</div>
					<div>接单{{album.send_order_num}}</div>
				</div>
			</div>
		</div>
		<van-swipe v-if="type==0" @change="onChange" class="swiper-container" :show-indicators="false">
			<van-swipe-item class="swiper-item" v-for="(item, index) in album.photos" :key="index">
				<img alt="" class="image-item" v-lazy="item" />
			</van-swipe-item>
		</van-swipe>
		<video v-if="type==1" class="album-video" :src="album.videos" autoplay controls></video>
		<div class="bottom-container">
			<div class="photo-num" v-if="type==0">{{current+1}}/{{album.photos.length}}</div>
			<div class="goods-name">{{album.title}}</div>
		</div>
	</div>
</template>

<script>
	import VanSwipe from "vant/lib/swipe";
	import VanSwipeItem from "vant/lib/swipe-item";
	import "vant/lib/swipe/style";
	import "vant/lib/swipe-item/style";
	export default {
		data() {
			return {
				type: -1,
				album: null,
				current:0
			}
		},
		components: {
			VanSwipe,
			VanSwipeItem
		},
		methods: {
			back(){
				this.$router.back(-1);
			},
			onChange(index){
				this.current = index
			},
			toMtDetail(id){
				this.$router.push({
					name: "modelDetail",
					query:{
						mt_id:id,
						fromPage:"other"
					}
				});
			}
		},
		created() {
			//console.log("页面接收参数", this.$route.query);
			this.type = this.$route.query.type;
			this.album = JSON.parse(this.$route.query.album);
			//console.log(this.album);
			if(this.type==0){
				this.album.photos = this.album.photos.split(";");
			}
		}
	}
</script>

<style lang="scss" scoped>
	
	.container {
		width: 100vw;
		height: 100vh;
		background-color: #000;
		z-index: 1000;
		display: flex;
		flex-direction: column;
	}

	.top-container {
		height: 180px;
		display: flex;
		align-items: center;
		.back-icon{
			width: 50px;
			height: auto;
			display: block;
		}
		.head-pic{
			width: 80px;
			height: 80px;
			border-radius: 50%;
			margin-right:15px;
		}
		.info-con{
			color: #fff;
			line-height: 45px;
			.mt-name{
				font-size: 28px;
			}
			.age-con{
				display: flex;
				font-size: 24px;
			}
		}
	}
	.album-video{
		flex: 1;
		width: 100%;
		max-height:88vh;
	}
	.swiper-container{
		flex:1;
		.swiper-item{
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
		.image-item{
			width:100%
		}
	}
	.bottom-container{
		height: 200px;
		padding: 0 20px;
		color: #fff;
		.photo-num{
			font-size: 36px;
		}
		.goods-name{
			font-size: 30px;
			margin-top: 15px;
		}
	}
</style>
