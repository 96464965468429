<template>
  <div class="header">
      <div class="title">{{tit}}</div>
  </div>
</template>

<script>
export default {
    props : {
        tit : {
            type : String
        }
    }
}
</script>

<style lang='scss' scoped>
.header{
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .title{
        font-size: 30px;
        font-weight: 700;
    }
}
</style>