<template>
	<div>
		<div class="more_itemdiv" @click="to_plaza">
			<img class="more_itemdiv_img" alt
				src="https://img.jimeimadou.com/common/resource/normal_icon/normal_tggc_icon.png" />
		</div>
		<scroll-y class="main_scroll" @loadMore="load_model">
			<div class="container">
				<div class="activity_container">
					<div class="activity_leftdiv" @click="toWeApp">
						<img class="activity_img" alt
							src="https://img.jimeimadou.com/common/resource/index_icon/index_yqzq_icon.png" />
					</div>
					<div class="activity_rightdiv">
						<img @click="to_rank" class="activity_righimg" alt
							src="https://img.jimeimadou.com/common/resource/index_icon/index_mtph_icon.png" />
						<img @click="to_help" class="activity_righimg" alt
							src="https://img.jimeimadou.com/common/resource/index_icon/index_sybz_icon.png" />
					</div>
				</div>

				<!-- 通告广场 -->
				<div class="plaza_container">
					<div class="plaza_content">
						<div class="plaza_titlediv">
							<div class="plaza_title_left">
								<div class="plaza_title_line"></div>
								<div class="plaza_title">通告广场</div>
							</div>
							<div class="plaza_title_more" @click="to_plaza">
								查看更多<span class="plaza_title_more_jiantou">></span>
							</div>
						</div>

						<div class="plaza_tablediv">
							<div class="plaza_tabledivcell" v-for="(item, index) in task_list" :key="index"
								@click.stop="to_goods_detail(item.id)">
								<div class="plaza_tabledivcell_contentdiv">
									<div class="plaza_tabledivcell_icondiv">
										<img v-lazy="item.goods_pic[0]" alt class="plaza_tabledivcell_icon" />
									</div>

									<div class="plaza_tabledivcell_desdiv">
										<div class="goods_title_content">{{ item.goods_name }}</div>
										<div style="overflow: hidden;">
											<task-tag :task_require="item.task_require"
												:task_require_sub="item.task_require_sub" :task_type="item.task_type"
												:xhs_prom_type="item.xhs_prom_type"></task-tag>
										</div>
										<div class="plaza_tabledivcell_botdiv">
											<div class="plaza_tabledivcell_numdiv">
												<div class="plaza_tabledivcell_num">已报名</div>
												<div class="plaza_tabledivcell_subnum">
													{{ item.bm_num }}
												</div>
											</div>
											<div class="plaza_tabledivcell_numdiv">
												{{item.format_time}}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- 他们在接单 -->
				<div class="user_container">
					<div class="plaza_title_con">
						<div :class="currentType==0?'current':''" @click="chooseType(0)">优质买家秀</div>
						<div :class="currentType==1?'current':''" @click="chooseType(1)">种草/口播</div>
					</div>

					<div class="user_tablediv">
						<!-- 左 -->
						<div class="user_tablediv_left">
							<div class="user_tabledivcell" :key="index" v-for="(item, index) in left_mt_list"
								@click="toAlbumDetail(item.user_id,item.id)">
								<div class="user_tabledivcell_contentdiv">
									<div class="mjx-photo-con">
										<img class="user_tabledivcell_img" v-lazy="item.cover_image" alt />
										<div class="photo-num-con" v-if="currentType==0">
											<img class="pic-icon" src="../../public/static/pic.png" alt="">
											<div>{{item.photo_num}}</div>
										</div>
										<div class="play-mask" v-if="currentType==1">
											<img class="play-icon" src="../../public/static/play.png" alt="">
										</div>
									</div>
									<div class="mjx-con">
										<div class="mjx-title" v-if="item.title">{{item.title}}</div>
										<div class="mt-info-con">
											<img class="mt_head_pic" v-lazy="item.head_ico" alt="" v-if="item.head_ico">
											<div class="mt-name" v-if="item.mt_name">{{item.mt_name}}</div>
											<div v-if="item.send_order_num">{{item.send_order_num}}单</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<!-- 右 -->
						<div class="user_tablediv_right">
							<div class="user_tabledivcell" :key="index" v-for="(item, index) in right_mt_list"
								@click="toAlbumDetail(item.user_id,item.id)">
								<div class="user_tabledivcell_contentdiv">
									<div class="mjx-photo-con">
										<img class="user_tabledivcell_img" v-lazy="item.cover_image" alt />
										<div class="photo-num-con" v-if="currentType==0">
											<img class="pic-icon" src="../../public/static/pic.png" alt="">
											<div>{{item.photo_num}}</div>
										</div>
										<div class="play-mask" v-if="currentType==1">
											<img class="play-icon" src="../../public/static/play.png" alt="">
										</div>
									</div>
									<div class="mjx-con">
										<div class="mjx-title" v-if="item.title">{{item.title}}</div>
										<div class="mt-info-con">
											<img class="mt_head_pic" v-lazy="item.head_ico" alt="" v-if="item.head_ico">
											<div class="mt-name" v-if="item.mt_name">{{item.mt_name}}</div>
											<div v-if="item.send_order_num">{{item.send_order_num}}单</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-if="is_end" class="bottom_end_flag">已经到底了哦~</div>
					</div>
				</div>
			</div>
		</scroll-y>
		<transition enter-active-class="slide_in" leave-active-class="slide_out">
			<router-view class="rank"></router-view>
		</transition>
	</div>
</template>

<script>
	var sign_module = require("../../public/js/sign");
	import qs from "qs";
	import ScrollY from "../components/app-scroll-y";
	import MyHeader from "../components/header";
	import taskTag from "../components/task-tag";
	import axios from "axios";
	export default {
		data() {
			return {
				task_list: [],
				model_list: [],
				left_mt_list: [],
				right_mt_list: [],
				model_page: 1,
				is_end: false,
				currentType: 0,
			};
		},
		components: {
			ScrollY,
			MyHeader,
			taskTag
		},
		methods: {
			to_goods_detail(id) {
				this.$router.push({
					name: "goodsdetail",
					query: {
						id,
					},
				});
				// this.$router.push("/goodsdetail?id=" + id);
			},
			chooseType(type) {
				this.currentType = type;
				this.model_page = 1;
				this.is_end = false;
				this.left_mt_list = [];
				this.right_mt_list = [];
				this.load_model();
			},
			to_login() {
				this.$router.push("/mine");
			},
			toWeApp(){
				this.$router.push({
					name: 'shareCode'
				})
			},
			load_model() {
				if (this.is_end) {
					return;
				}
				var initparams = {
					page: this.model_page++,
					page_size: "10",
				};
				let requestUrl = "";
				switch (this.currentType) {
					case 0:
						requestUrl = process.env.VUE_APP_URL + "/mobi/index/mjx";
						break;
					case 1:
						requestUrl = process.env.VUE_APP_URL + "/mobi/index/zhongcao";
						break;
					default:
						break;
				}
				var params = sign_module.signParams(initparams);
				params["_platform"] = "mobi";
				params["_version"] = "1.0.0";
				axios
					.post(requestUrl, qs.stringify(params), {
						headers: {
							"Content-Type": "application/x-www-form-urlencoded",
						},
					})
					.then((res) => {
						var list = JSON.parse(res.data.data).list;
						console.log("列表数据", list);
						if (list.length == 0) {
							this.is_end = true;
							return;
						}
						if (this.currentType == 0) {
							list.map((item) => {
								item.cover_image =
									item.photos.split(";")[0] +
									"?imageView2/2/w/700|watermark/1/image/aHR0cDovL2ptc3RhdGljLTEyNTU0OTc5MTYuY29zLmFwLXNoYW5naGFpLm15cWNsb3VkLmNvbS9jb21tb24vd2F0ZXJtYXJrbWlkZGxleDJ4LnBuZw/dissolve/25/dx/10/dy/10/ws/0.3/format/webp";
								item.photo_num = item.photos.split(";").length;
							});
						} else {
							list.map((item) => {
								item.cover_image =
									item.cover +
									"?imageView2/2/w/700|watermark/1/image/aHR0cDovL2ptc3RhdGljLTEyNTU0OTc5MTYuY29zLmFwLXNoYW5naGFpLm15cWNsb3VkLmNvbS9jb21tb24vd2F0ZXJtYXJrbWlkZGxleDJ4LnBuZw/dissolve/25/dx/10/dy/10/ws/0.3/format/webp";
							});
						}
						this.pool_format(0, list);
					});
			},
			pool_format(index, list) {
				if (index >= list.length) {
					return;
				}
				//右边列表
				var rightlist = this.$el.querySelector(".user_tablediv_right");
				var right_h = rightlist.getBoundingClientRect().height;
				//左边列表
				var leftlist = this.$el.querySelector(".user_tablediv_left");
				var left_h = leftlist.getBoundingClientRect().height;
				// console.log(right_h, left_h);
				if (left_h <= right_h) {
					this.left_mt_list.push(list[index]);
				} else {
					this.right_mt_list.push(list[index]);
				}
				this.$nextTick(() => {
					setTimeout(() => {
						this.pool_format(index + 1, list);
					}, 100);
				});
			},
			to_plaza() {
				this.$router.push("/plaza");
			},
			to_rank() {
				this.$router.push({
					name: "rank",
				});
			},
			to_mt_detail(album) {
				console.log(album);
				// this.$router.push("/main/album");
				this.$router.push({
					path: "/main/album",
					query: {
						type: this.currentType,
						album: JSON.stringify(album),
					},
				});
			},
			toAlbumDetail(mt_id,album_id) {
				this.$router.push({
					name: "albumDetail",
					query:{
						mt_id,
						album_id,
						album_type:this.currentType+1
					}
				});
			},
			to_help() {
				this.$router.push({
					name: "noviceCourse",
				});
			},
		},
		created() {
			axios.post(process.env.VUE_APP_URL + "/mobi/index/taskv3").then((res) => {
				// console.log(res);
				if(res.data.status=="y"){
					var list = JSON.parse(res.data.data);
					//console.log("首页通告数据", list);
					for (var i = 0; i < list.length; i++) {
						list[i].goods_pic = list[i].goods_pic.split(",");
					}
					this.task_list = list;
				}
			});
			this.load_model();
			// console.log(process.env);
		},
	};
</script>

<style lang="scss" scoped>
	.main_scroll {
		position: absolute;
		top: 0;
		bottom: 100px;
		left: 0;
		right: 0;
		background: #f2f3f7;
	}

	.rank {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		// height: 100vh;
		// width: 100%;
	}

	@keyframes slide-in {
		0% {
			transform: translateX(100%);
		}

		100% {
			transform: translateX(0);
		}
	}

	@keyframes slide-out {
		0% {
			transform: translateX(0);
		}

		100% {
			transform: translateX(100%);
		}
	}

	.slide_in {
		animation: slide-in 300ms ease-in;
	}

	.slide_out {
		animation: slide-out 300ms ease-in;
	}

	.plaza_title_more {
		font-size: 26px;
		color: #8a8e99;
	}

	.plaza_title_more_jiantou {
		font-family: "宋体";
		font-size: 26px;
	}

	.bottom_end_flag {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 60rpx;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #8a8e99;
	}

	/**index.wxss**/

	.activity_container {
		width: 100%;
		padding: 20px 24px 0 24px;
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
	}

	.activity_leftdiv {
		width: 346px;
		height: 240px;
	}

	.activity_rightdiv {
		width: 346px;
		height: 240px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.activity_img {
		width: 100%;
		height: 100%;
		border-radius: 10px;
	}

	.activity_righimg {
		width: 100%;
		border-radius: 10px;
		height: 115px;
	}

	.plaza_container {
		width: 100%;
		padding: 0 24px;
		box-sizing: border-box;
		margin-top: 20px;
	}

	.plaza_content {
		background-color: white;
		width: 100%;
		border-radius: 24px;
	}

	.plaza_titlediv {
		width: 100%;
		height: 80px;
		padding: 0 24px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.plaza_title_left {
		display: flex;
		align-items: center;
	}

	.plaza_title_line {
		width: 6px;
		height: 28px;
		background: #ff6666;
		border-radius: 3px;
	}

	.plaza_title {
		font-size: 28px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #262a33;
		margin-left: 6px;
	}

	.plaza_tablediv {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		padding: 0 24px;
	}

	.plaza_tabledivcell {
		width: 208px;
		margin-bottom: 20px;
		overflow: hidden;
	}

	.plaza_tabledivcell_contentdiv {
		width: 100%;
		background-color: white;
	}

	.plaza_tabledivcell_icondiv {
		width: 208px;
		height: 208px;
		display: block;
	}

	.plaza_tabledivcell_icon {
		width: 100%;
		height: 100%;
		border-radius: 10px;
		display: block;
		object-fit: cover;
	}

	.goods_title_content {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		font-size: 24px;
		line-height: 40px;
		margin-top: 6px;
		font-weight: 700;
	}

	.type-con {
		white-space: nowrap;
		overflow: hidden;
	}

	.goods_title_div {
		padding: 0 6px;
		box-sizing: border-box;
		background: #ff6685;
		color: #fff;
		font-size: 20px;
		line-height: 30px;
		margin-right: 6px;
		border-radius: 4px;
		display: inline-block;
	}

	.goods_labelimg {
		width: 13px;
		height: 14px;
		display: inline-block;
	}

	.plaza_tabledivcell_botdiv {
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: #aaa;
		line-height: 40px;
	}

	.plaza_tabledivcell_numdiv {
		display: flex;
		align-items: center;
		font-size: 20px;
	}

	.plaza_tabledivcell_num {
		color: #8a8e99;
	}

	.plaza_tabledivcell_subnum {
		color: #262a33;
		margin-left: 4px;
	}

	.plaza_title_con {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 120px;

		div {
			color: #000;
			font-weight: 700;
			font-size: 32px;
			padding: 15px 0;

			&:last-child {
				margin-left: 80px;
			}
		}

		.current {
			color: #ff6685 !important;
			position: relative;

			&::after {
				content: "";
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 8px;
				border-radius: 4px;
				background-image: linear-gradient(to right,
						rgba(255, 179, 200, 1),
						rgba(255, 102, 133, 1));
			}
		}
	}

	.user_titlediv {
		margin-top: 17px;
		width: 100%;
		height: 66px;
		line-height: 66px;
		color: #262a33;
		font-size: 28px;
		font-family: PingFangSC-Semibold, PingFang SC;
		padding: 0 24px;
		box-sizing: border-box;
	}

	.user_tablediv {
		position: relative;
		width: 100%;
		padding: 0 15px 30px;
		box-sizing: border-box;
		display: flex;
		flex-direction: row;
	}

	.user_tablediv_left {
		display: flex;
		flex-direction: column;
		width: 50%;
		align-self: start;
	}

	.user_tablediv_right {
		display: flex;
		flex-direction: column;
		width: 50%;
		align-self: start;
	}

	.user_tabledivcell {
		padding: 0 9px;
		box-sizing: border-box;
		width: 100%;
		display: flex;
		justify-content: center;
	}

	.user_tabledivcell_contentdiv {
		margin-bottom: 18px;
		border-radius: 15px;
		background-color: #fff;
		width: 100%;

		.mjx-photo-con {
			position: relative;

			.user_tabledivcell_img {
				width: 100%;
				border-radius: 15px 15px 0 0;
				display: block;
			}

			.photo-num-con {
				position: absolute;
				right: 10px;
				bottom: 10px;
				color: #fff;
				display: flex;
				align-items: center;
				height: 40px;
				padding: 0 15px;
				border-radius: 20px;
				background-color: rgba(0, 0, 0, 0.5);
				font-size: 16px;

				.pic-icon {
					width: 25px;
					height: 25rpx;
					display: block;
					margin-right: 3px;
				}
			}

			.play-mask {
				position: absolute;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
				border-radius: 15px 15px 0 0;
				// background-color: rgba(0,0,0,0.1);
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				.play-icon {
					width: 60px;
					height: 60px;
					display: block;
				}
			}
		}

		.mjx-con {
			padding: 15px;

			.mjx-title {
				font-size: 28px;
				margin-bottom: 20px;
				line-height: 40px;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				overflow: hidden;
				text-overflow: ellipsis;
				-webkit-box-orient: vertical;
			}

			.mt-info-con {
				display: flex;
				align-items: center;

				.mt_head_pic {
					width: 50px;
					height: 50px;
					border-radius: 50%;
					margin-right: 10px;
				}

				.mt-name {
					flex: 1;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
				}
			}
		}
	}

	.user_tabledivcell_imagediv {
		border-radius: 14px;
		overflow: hidden;
		background-color: white;
		width: 100%;
		position: relative;
	}

	.user_msg_content {
		padding: 0 4px;
		box-sizing: border-box;
		position: absolute;
		bottom: 10px;
		left: 10px;
		max-width: 240px;
		height: 50px;
		background-color: rgba(0, 0, 0, 0.2);
		border-radius: 25px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 24px;
		font-family: PingFangSC-Regular, PingFang SC;
		color: #ffffff;
	}

	.user_msg_div {
		display: flex;
		align-items: center;
	}

	.user_msg_icon {
		width: 40px;
		height: 40px;
		flex-shrink: 0;
		border-radius: 50%;
	}

	.user_msg_title {
		padding: 0 12px;
		word-break: break-all;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1;
		overflow: hidden;
	}

	.user_msg_num {
		flex-shrink: 0;
	}

	.more_itemdiv {
		z-index: 1000;
		position: fixed;
		bottom: 200px;
		right: 20px;
		width: 90px;
		height: 86px;
	}

	.more_itemdiv_img {
		width: 100%;
		height: 100%;
	}
</style>
