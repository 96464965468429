<template>
	<div class="rank_wrap">
		<div class="header">
			<div class="rank_back" @click="back">
				<img class="back_img"
					src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/normal_icon/Back%403x.png"
					alt />
			</div>
			<div class="title">排行榜</div>
			<div class="kong"></div>
		</div>

		<scroll-y class="scroll_wrap" :loadMore="handleMore">
			<div class="container">
				<div class="top_wrap">
					<div class="btn_wrap">
						<div class="btn_content">
							<van-popover
									v-model="showPopover"
									trigger="click"
									:actions="actions"
									@select="onSelect">
								<template #reference>
									<div class="btn_item" :style="{background: '#FFB3C3',color: '#fff'}">
										{{select_time==7 ? '近7天':'近30天'}}
										<span class="iconfont icon-arrow-down-bold"></span>
									</div>
								</template>
							</van-popover>
							<div class="btn_item order" @click="change_list(0)" :style="{
                  background: selectedindex == 0 ? '#FFE6EA' : '#FFB3C3',
                  color: selectedindex == 0 ? '#333' : '#fff',
                  fontWeight: selectedindex == 0 ? 'bold' : '' }">
								接单榜
							</div>
							<div class="btn_item" @click="change_list(1)" :style="{
                  background: selectedindex == 1 ? '#FFE6EA' : '#FFB3C3',
                  color: selectedindex == 1 ? '#333' : '#fff',
                  fontWeight: selectedindex == 1 ? 'bold' : ''}">
								收益榜
							</div>
						</div>
					</div>
					<div class="top_tip">-- 排名信息每天都会更新 --</div>
					<!-- 前三 -->

					<div v-if="rank_list.length != 0" class="top_three_wrap"
						style="background-image : url('https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/normal_icon/rank_bg%403x.png')">
						<div class="top_one" @click="to_mt_detail(rank_list[0].user_id)">
							<div class="head_ico_wrap_one"
								style="background-image: url('https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/index_icon/one_bg%403x.png')">
								<div class="head_ico_one" :style="{
                    'background-image': 'url(' + rank_list[0].head_ico + ')',
                    'border-color': '#FFE28C',
                  }"></div>
								<img alt class="ph_pic"
									src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/index_icon/ph01%403x.png" />
							</div>
							<div class="top_nick_name">{{ rank_list[0].nick_name }}</div>
							<div v-if="selectedindex == 0" class="top_amount">
								{{ rank_list[0].send_order_num }}单
							</div>
							<div v-if="selectedindex == 1" class="top_amount">
								￥{{ rank_list[0].total_price }}
							</div>
							<div class="top_one_takeplace"></div>
						</div>
						<div class="top_two" @click="to_mt_detail(rank_list[1].user_id)">
							<div class="head_ico_wrap_two_three">
								<div class="head_ico_two_three" :style="{
                    'background-image': 'url(' + rank_list[1].head_ico + ')',
                    'border-color': '#E7ECF8',
                  }"></div>
								<img alt="" class="ph_pic"
									src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/index_icon/ph02%403x.png" />
							</div>
							<div class="top_nick_name">{{ rank_list[1].nick_name }}</div>
							<div v-if="selectedindex == 0" class="top_amount">
								{{ rank_list[1].send_order_num }}单
							</div>
							<div v-if="selectedindex == 1" class="top_amount">
								￥{{ rank_list[1].total_price }}
							</div>
						</div>
						<div class="top_three" @click="to_mt_detail(rank_list[2].user_id)">
							<div class="head_ico_wrap_two_three">
								<div class="head_ico_two_three" :style="{
                    'background-image': 'url(' + rank_list[2].head_ico + ')',
                    'border-color': '#F6DCBC',
                  }"></div>
								<img alt="" class="ph_pic"
									src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/index_icon/ph03%403x.png" />
							</div>
							<div class="top_nick_name">{{ rank_list[2].nick_name }}</div>
							<div v-if="selectedindex == 0" class="top_amount">
								{{ rank_list[2].send_order_num }}单
							</div>
							<div v-if="selectedindex == 1" class="top_amount">
								￥{{ rank_list[2].total_price }}
							</div>
						</div>
					</div>
				</div>
				<!-- 底部 -->
				<div class="bottom_wrap">
					<div class="bottom_title_wrap">
						<div class="bottom_title_l">排名</div>
						<div class="mt_title">模特</div>
						<div class="bottom_title_r">
							<div v-if="selectedindex == 0">接单量</div>
							<div class="ji_zeng_wrap" v-if="selectedindex == 1">
								<div @click="btn_show_jz">
									{{ jz_index == 2 ? "寄拍佣金" : "赠拍价值" }}
									<img alt="" class="jz_choose_ico"
										src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/normal_icon/order_xia_icon.png" />
								</div>
								<div v-if="show_jz" class="ji_zeng_choose">
									<div @click="change_jz(2)">寄拍佣金</div>
									<div class="ji_zeng_line"></div>
									<div @click="change_jz(1)">赠拍价值</div>
								</div>
							</div>
						</div>
					</div>

					<div v-for="(item, index) in rank_list" @click="to_mt_detail(item.user_id)"
						:key="index">
						<div class="bottom_list_item" v-if="index>2">
							<div class="bottom_rank_num">{{ index + 1 }}</div>
							<div class="bottom_rank_info">
								<div class="bottom_head_ico_wrap">
									<div class="bottom_head_ico" :style="{
							  'background-image': 'url(' + item.head_ico + ')',
							}"></div>
								</div>
								<div class="bottom_user_text">
									<div class="bottom_text_top omit">{{ item.nick_name }}</div>
									<div class="bottom_text_bottom omit">
										{{ item.autograph ? item.autograph : "暂无签名" }}
									</div>
								</div>
							</div>
							<div v-if="selectedindex == 0" class="bottom_rank_score">
								{{ item.send_order_num }}单
							</div>
							<div v-else class="bottom_rank_score">
								￥{{ item.total_price }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</scroll-y>
	</div>
</template>

<script>
	var sign_module = require("../../public/js/sign");
	import qs from "qs";
	import ScrollY from "../components/app-scroll-y";
	import axios from "axios";
	export default {
		components: {
			ScrollY,
		},
		data() {
			return {
				rank_list: [],
				selectedindex: 0,
				list_type: "order_num",
				jz_index: 1,
				show_jz: false,
				select_time: 30,
				actions:[
					{text: '近7天'},
					{text: '近30天'},
				],
				showPopover: false
			};
		},
		methods: {
			back() {
				this.$router.back();
			},
			onSelect(e,index){
				this.select_time = index ? 30 : 7;
				this.load_data();
			},
			change_list(index) {
				if (index == this.selectedindex) {
					return;
				}
				this.selectedindex = index;
				if (index == 0) {
					this.list_type = "order_num";
				} else {
					this.list_type = "commission";
				}
				this.load_data();
			},
			load_data() {
				var initparams = {
					task_type: this.jz_index,
					type: this.list_type,
					sel_date: this.select_time
				};
				var params = sign_module.signParams(initparams);
				params["_platform"] = "mobi";
				params["_version"] = "1.0.0";
				axios
					.post(
						process.env.VUE_APP_URL + "/mobi/mote/rankinglist",
						qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						//   console.log(JSON.parse(res.data.data));
						this.rank_list = JSON.parse(res.data.data);
					});
			},
			btn_show_jz() {
				this.show_jz = !this.show_jz;
			},
			change_jz(index) {
				this.show_jz = false;
				if (index == this.jz_index) {
					return;
				}
				this.jz_index = index;
				this.load_data();
			},
			handleMore() {},
			to_mt_detail(id) {
				this.$router.push({
					name: "mtdetail",
					params: {
						id
					}
				});
			},
		},
		created() {
			this.load_data();
		},
	};
</script>

<style lang="scss" scoped>
	.rank_wrap {
		height: 100%;
		width: 100%;
		background: linear-gradient(to bottom,
				#ff6a8a 0%,
				#ff6a8a 50%,
				#fff 50%,
				#fff 100%);

		opacity: 1;
		z-index: 1000;

		.header {
			width: 100%;
			height: 80px;
			background: #ff6a8a;
			display: flex;
			justify-content: space-between;
			align-items: center;
			box-sizing: border-box;
			padding: 0 20px;

			.rank_back {
				border-radius: 50%;
				background: rgba(220, 220, 220, 0.5);
				height: 50px;
				width: 50px;
				z-index: 500;
				display: flex;
				justify-content: center;
				align-items: center;

				.back_img {
					height: 80%;
					width: 80%;
					margin-left: 10px;
					opacity: 0.6;
				}
			}

			.title {
				font-size: 34px;
				color: #fff;
				font-weight: bold;
				transform: translateX(-20px);
			}
		}
	}

	.scroll_wrap {
		position: absolute;
		top: 90px;
		bottom: 0;
		left: 0;
		right: 0;
		.container {
			padding-top: 50px;
		}
	}

	.top_wrap {
		position: relative;
		height: 540px;
		width: 100%;
		background: #ff6a8a;
	}

	.btn_wrap {
		height: 80px;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.btn_content {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		box-sizing: border-box;
		width: 90%;
		box-sizing: border-box;
		border: 6px #fff solid;
		border-radius: 50px;
		overflow: hidden;
	}

	.btn_item, .van-popover__wrapper {
		height: 100%;
		width: 33.3%;
		background: #ffb3c3;
		font-size: 32px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
		&.order{
			border-left: 5px solid #fff;
			border-right: 5px solid #fff;
		}
		.iconfont {
			font-size: 24px;
			margin-left: 6px;
		}
		.btn_item {
			width: 100%;
		}
	}

	.top_tip {
		margin: 20px;
		text-align: center;
		color: #fff;
	}

	.top_three_wrap {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 400px;
		background-size: 90%;
		background-position: center bottom;
		background-repeat: no-repeat;
	}

	.top_two {
		height: 78%;
		width: 27%;
		position: absolute;
		left: 6%;
		bottom: 0;
		border-radius: 16px 16px 0 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.top_three {
		height: 72%;
		width: 27%;
		position: absolute;
		right: 6%;
		bottom: 0;
		border-radius: 16px 16px 0 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.top_one {
		height: 90%;
		width: 35%;
		position: absolute;
		top: 10%;
		left: 50%;
		transform: translateX(-50%);
		border-radius: 16px 16px 0 0;
		z-index: 1000;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		box-sizing: border-box;
		padding-bottom: 20px;
	}

	.mask_left {
		height: 30%;
		width: 30px;
		background: #fff;
		position: absolute;
		bottom: 0;
		left: 34%;
		transform: translateX(-50%);
		z-index: 1001;
	}

	.mask_center {
		height: 10%;
		width: 30%;
		position: absolute;
		bottom: 0;
		right: 50%;
		transform: translateX(50%);
		z-index: 1001;
		background: #fff;
	}

	.mask_right {
		height: 30%;
		width: 30px;
		background: #fff;
		position: absolute;
		bottom: 0;
		right: 34%;
		transform: translateX(50%);
		z-index: 1001;
	}

	.head_ico_wrap_one {
		position: relative;
		width: 80%;
		height: 190px;
		background-size: cover;
		background-position: left bottom;
	}

	.head_ico_wrap_two_three {
		position: relative;
		width: 80%;
		height: 150px;
		background-size: cover;
		background-position: center center;
	}

	/* .top_one_takeplace{
  width: 100%;
  height: 10%;
} */
	.head_ico_one {
		position: absolute;
		top: 0px;
		left: 50%;
		transform: translateX(-50%);
		padding: 34%;
		border-radius: 50%;
		overflow: hidden;
		background-size: cover;
		background-position: center center;
		border: 6px #333 solid;
	}

	.head_ico_two_three {
		position: absolute;
		top: 0px;
		left: 50%;
		transform: translateX(-50%);
		padding: 32%;
		border-radius: 50%;
		overflow: hidden;
		background-size: cover;
		background-position: center center;
		border: 6px #333 solid;
	}

	.ph_pic {
		height: auto;
		width: 100%;
		position: absolute;
		bottom: 0px;
	}

	.top_nick_name {
		font-size: 34px;
		font-weight: bolder;
		width: 80%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		text-align: center;
		line-height: 50px;
	}

	.top_amount {
		color: #ff6487;
		font-size: 30px;
		font-weight: bolder;
		line-height: 50px;
	}

	.bottom_wrap {
		background: #fff;
	}

	.bottom_title_wrap {
		height: 80px;
		box-sizing: border-box;
		line-height: 80px;
		padding: 0 20px;
		background-color: #f7f7f7;
	}

	.bottom_title_l {
		float: left;
		font-weight: bold;
		font-size: 28px;
	}

	.mt_title {
		float: left;
		width: 50%;
		padding: 0 20px;
		font-weight: bold;
		font-size: 28px;
	}

	.bottom_title_r {
		float: right;
		font-weight: bold;
		font-size: 28px;
	}

	.bottom_list_item {
		margin: 0 auto;
		background: #fff;
		border-top: 1px solid#E8E8E8;
		height: 130px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.bottom_rank_num {
		font-size: 36px;
		font-weight: bolder;
		width: 80px;
		text-align: center;

	}

	.bottom_rank_info {
		display: flex;
		flex: 1;
		overflow: hidden;
	}

	.bottom_head_ico {
		width: 100px;
		height: 100px;
		background-size: 100%;
		background-position: center center;
		border-radius: 50%;
		overflow: hidden;
	}

	.bottom_user_text {
		margin-left: 20px;
		overflow: hidden;
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
	}

	.bottom_text_top {
		font-size: 34px;
		color: #282830;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.bottom_text_bottom {
		font-size: 28px;
		color: #8c8c8c;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.bottom_rank_score {
		color: #f66781;
		font-weight: bolder;
		margin-right: 24px;
		font-size: 28px;
	}

	.ji_zeng_wrap {
		position: relative;
		display: flex;
		align-items: center;
	}

	.jz_choose_ico {
		width: 30px;
		height: auto;
	}

	.ji_zeng_choose {
		position: absolute;
		top: 80px;
		right: 10px;
		height: 200px;
		width: 240px;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		background-color: #fff;
		border-radius: 8px;
		box-shadow: 0 0 5px #ccc;
	}

	.ji_zeng_line {
		width: 80%;
		border: 1px solid gray;
	}

	.van-popup {
		.van-popover__action {
			width: 2.27rem;
			padding: 0.2rem 0.26rem;
			height: 0.93rem;
		}
		.van-popover__action-text {
			font-size: 0.4rem;
		}
	}
</style>
